import React, { useState } from "react";
import { db } from "./firebase-config"; // Make sure you have this file set up with your Firebase config
import { collection, addDoc } from "firebase/firestore";
import styled from "styled-components";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Increased gap for better spacing */
    width: 100%;
    max-width: 400px; /* Limit form width for better alignment */
    padding: 20px;
`;

const Input = styled.input`
    padding: 15px;
    border-radius: 8px;
    border: 2px solid #ccc;
    width: calc(100% - 30px); /* Adjust width based on padding */
    box-sizing: border-box; /* Include padding in width calculation */

    &:focus {
        border-color: #007bff; /* Highlight on focus */
        outline: none; /* Remove default outline */
    }
`;

const Button = styled.button`
    padding: 12px 25px;
    border-radius: 8px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; /* Smooth transition for background color */
    margin: 5px 15px; // This adds space between the buttons
    font-size: 1em;

    &:hover,
    &:focus {
        background-color: #0056b3;
        outline: none;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

// Add a new component for the instructions
const Instructions = styled.p`
    text-align: center;
    color: #4a4a4a; /* Softer than pure black for a more modern look */
    font-size: 1.2rem; /* Slightly larger for readability */
    font-weight: 500; /* Medium weight for emphasis */
    line-height: 1.6; /* For better readability */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* A clean, modern font */
    text-shadow: 1px 1px 0px #fff; /* Subtle text shadow for depth */
`;

export const GuessForm = ({ setReveal }) => {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (genderGuess) => {
        if (!name.trim()) return; // Simple validation to ensure a name is entered

        setLoading(true);
        try {
            // Add the guess to the "guesses" collection in Firestore
            await addDoc(collection(db, "guesses"), {
                name,
                guess: genderGuess,
                timestamp: new Date(),
            });
            setLoading(false);
            setReveal(true); // Trigger the reveal
        } catch (error) {
            console.error("Error adding document: ", error);
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
                <Instructions>
                    Hey there! Auston and Kelsy are excited to welcome their new baby due on September 23rd, 2024. What do you think the gender will be? Enter your name and take a guess!
                </Instructions>

            <Input type="text" placeholder="Enter your name..." aria-label="Your name" value={name} onChange={(e) => setName(e.target.value)} disabled={loading} />
            <div>
                <Button type="button" onClick={() => handleSubmit("male")} disabled={loading}>
                    Male
                </Button>
                <Button type="button" onClick={() => handleSubmit("female")} disabled={loading}>
                    Female
                </Button>
            </div>
        </Form>
    );
};
