import React, { useState, useEffect } from 'react';
import { GuessForm } from './GuessForm';
import { RevealComponent } from './RevealComponent';
import GlobalStyles from './GlobalStyles';


  
  

function App() {
  const [reveal, setReveal] = useState(false);

  // Load the guess status from local storage on component mount
  useEffect(() => {
    const hasGuessed = localStorage.getItem('hasGuessed') === 'true'; // Convert string to boolean

    if (hasGuessed) {
      setReveal(true);
    }
  }, []);

  const handleSetReveal = (newReveal) => {
    setReveal(newReveal);
    localStorage.setItem('hasGuessed', newReveal.toString());
  };

  return (
    <>
      <GlobalStyles />
      {!reveal ? (
        <GuessForm setReveal={handleSetReveal} />
      ) : (
        <RevealComponent gender={"female"} revealTimeUtc={"2024-03-24T23:32:00Z"}/>
      )}
    </>
  );
}

export default App;
