import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import { CountdownTimer } from './CountdownTimer'; // Adjust the import path as needed

const Text = styled(motion.div)`
  color: ${props => props.color || '#000'};
  text-align: center;
  font-size: 2rem;
`;

const GenderText = styled(motion.div)`
  color: ${props => props.color || '#000'};
  text-align: center;
  font-size: 5rem;
`;

const messageVariants = {
    hidden: { y: '-1vh', opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 50, mass: 0.5, damping: 10 }
    },
  };

export const RevealComponent = ({ gender, revealTimeUtc }) => {
  const [revealStep, setRevealStep] = useState(0);

  const messages = [
    'Alright, here we go...',
    'Drumroll please... 🥁',
    "It's a....",
    '...',
    gender === "female" ? "Girl!" : "Boy!",
  ];

  const genderColor = gender === "female" ? "#ff69b4" : "#1e90ff";

  const handleRevealTime = () => {
    // Begin the reveal sequence
    const revealInterval = setInterval(() => {
      setRevealStep((prevStep) => {
        if (prevStep < messages.length) {
          return prevStep + 1;
        } else {
          clearInterval(revealInterval);
          return prevStep;
        }
      });
    }, 4000); // Adjust time as needed for pacing
  };

  return (
    <>
      {revealStep === 0 ? (
        <CountdownTimer revealTimeUtc={revealTimeUtc} onRevealTime={handleRevealTime} />
      ) : revealStep < messages.length ? (
        <Text
          key={`message-${revealStep}`} // Unique key for each step
          initial="hidden"
          animate="visible"
          variants={messageVariants}
        >
          {messages[revealStep - 1]}
        </Text>
      ) : (
        <>
          <Confetti />
          <GenderText
            color={genderColor}
            initial="hidden"
            animate="visible"
            variants={messageVariants}
          >
            {messages[messages.length - 1]}
          </GenderText>
        </>
      )}
    </>
  );
};
