import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion"; // Import motion
import GuessesCounter from "./GuessesCounter";

const Countdown = styled(motion.div)`
    // Convert to motion.div
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
`;

const TimeSegment = styled.div`
    padding: 10px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 0 5px;
`;

const TimeValue = styled.span`
    font-size: 2.5rem;
    color: #333;
    display: block;
    margin: 0 5px;
`;

const TimeLabel = styled.span`
    font-size: 1rem;
    color: #666;
`;

const Instructions = styled.p`
    max-width: 400px;
    text-align: center;
    color: #4a4a4a; /* Softer than pure black for a more modern look */
    font-size: 1.2rem; /* Slightly larger for readability */
    font-weight: 500; /* Medium weight for emphasis */
    line-height: 1.6; /* For better readability */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* A clean, modern font */
    text-shadow: 1px 1px 0px #fff; /* Subtle text shadow for depth */
`;

const Button = styled.button`
    padding: 12px 25px;
    border-radius: 8px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; /* Smooth transition for background color */
    margin: 5px 15px; // This adds space between the buttons
    font-size: 1em;
    justify-self: center;

    &:hover,
    &:focus {
        background-color: #0056b3;
        outline: none;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

// Animation variants for the countdown
const countdownVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0, transition: { duration: 1.5 } },
};

// Animation variants for the entire component
const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0, transition: { duration: 1.5 } },
};

export const CountdownTimer = ({ revealTimeUtc, onRevealTime }) => {
    const [timeLeft, setTimeLeft] = useState(null);
    const [isFinished, setIsFinished] = useState(false); // Track if countdown is finished

    useEffect(() => {
        const revealTimeDate = new Date(revealTimeUtc);
        if((revealTimeDate - (new Date())) < 0) {
            console.log("BNOPE");
            setTimeLeft(0);
            return;
        }

        const interval = setInterval(() => {
            const now = new Date();
            const distance = revealTimeDate - now;

            if (distance < 0) {
                clearInterval(interval);
                setTimeLeft(0);
                setIsFinished(true); // Set countdown as finished
                onRevealTime();
            } else {
                setTimeLeft(distance);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [revealTimeUtc, onRevealTime]);

    const formatTimeLeft = () => {
        if (timeLeft === null) return <></>;

        let seconds = Math.floor((timeLeft / 1000) % 60);
        let minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        let hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        return (
            <>
                <TimeSegment>
                    <TimeValue>{hours}</TimeValue>
                    <TimeLabel>Hours</TimeLabel>
                </TimeSegment>
                <TimeSegment>
                    <TimeValue>{minutes}</TimeValue>
                    <TimeLabel>Minutes</TimeLabel>
                </TimeSegment>
                <TimeSegment>
                    <TimeValue>{seconds}</TimeValue>
                    <TimeLabel>Seconds</TimeLabel>
                </TimeSegment>
            </>
        );
    };

    return (
        <motion.div
            variants={componentVariants}
            initial="visible"
            animate={isFinished ? "hidden" : "visible"} // This now controls the fade-out of the entire content
            style={{
                display: "flex", // Use flex layout
                flexDirection: "column", // Stack children vertically
                alignItems: "center", // Center children horizontally
                marginTop: "3em",
            }}
        >
            {/* <Instructions>
                Hang tight! The big reveal is coming up when the countdown ends. It's perfectly fine to close this page and return later.
            </Instructions>
            <Countdown>
                {formatTimeLeft()}
            </Countdown> */}
            <Instructions>When you are ready to see the gender, click the button below.</Instructions>
            <Button onClick={() => {setIsFinished(true); onRevealTime()}}>Reveal!</Button>
            <GuessesCounter />
        </motion.div>
    );
};
