import React, { useEffect, useState } from "react";
import { db } from "./firebase-config";
import { collection, onSnapshot } from "firebase/firestore";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import styled from "styled-components";

const GuessesCounter = () => {
    const [guessCounts, setGuessCounts] = useState({ boys: 0, girls: 0 });

    useEffect(() => {
        const guessesCollectionRef = collection(db, "guesses");
        const unsubscribe = onSnapshot(guessesCollectionRef, (snapshot) => {
            let boysCount = 0;
            let girlsCount = 0;

            snapshot.docs.forEach((doc) => {
                const data = doc.data();
                if (data.guess === "male") {
                    boysCount++;
                } else if (data.guess === "female") {
                    girlsCount++;
                }
            });

            setGuessCounts({ boys: boysCount, girls: girlsCount });
        });

        return () => unsubscribe();
    }, []);

    const data = [
        { name: "Boy", value: guessCounts.boys },
        { name: "Girl", value: guessCounts.girls },
    ];

    const COLORS = ["#0088FE", "#FF69B4"];

    const Instructions = styled.p`
        max-width: 400px;
        text-align: center;
        color: #4a4a4a; /* Softer than pure black for a more modern look */
        font-size: 1rem; /* Slightly larger for readability */
        font-weight: 200; /* Medium weight for emphasis */
        line-height: 1.5; /* For better readability */
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* A clean, modern font */
    `;

    return (
        <div
            style={{
                display: "flex", // Use flex layout
                flexDirection: "column", // Stack children vertically
                alignItems: "center", // Center children horizontally
                marginTop: "3em",
            }}
        >
            <Instructions>The chart below shows the distribution of guesses so far and will update automatically as more guesses come in.</Instructions>
            <PieChart width={350} height={250}>
                <Pie data={data} labelLine={false} outerRadius={80} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </div>
    );
};

export default GuessesCounter;
